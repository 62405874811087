<template>
    <form-view 
        v-slot="{ onSuccess, onBack }"
        title="Новый тип лицензии"
        create
        url-update="/license_type/update/:id"
        url-back="/license_type"
    >
        <license-type-form @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import LicenseTypeForm from '@/components/forms/LicenseTypeForm.vue'
export default {
    name: 'LicenseTypeCreate',
    components: { FormView, LicenseTypeForm }
}
</script>