<template>
    <v-form>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-switch
          v-model="form.active"
          label="Активность"
        ></v-switch>

        <v-text-field 
            v-model="$v.form.code.$model"
            :error-messages="getErrors('form.code', {
                pattern: 'Неверный код'
            })"
            label="Символьный код" 
            name="code" 
            type="text" 
            placeholder=" "
            autofocus="autofocus" 
            color="light-green" 
        ></v-text-field>

        <v-text-field 
            v-model="$v.form.name.$model"
            :error-messages="getErrors('form.name')"
            label="Название" 
            name="name" 
            type="text" 
            placeholder=" "
            color="light-green" 
        ></v-text-field>

        <v-select
          v-model="$v.form.product.$model"
          :error-messages="getErrors('form.product')"
          required
          :items="products"
          label="Продукт"
        ></v-select>

        <v-divider class="my-4" />

        <v-row justify="start" align="center">
            <v-col>
                <div class="text-caption">Параметры лицензии</div>
            </v-col>
        </v-row>

        <v-select
          v-model="$v.form.data.subject.$model"
          :error-messages="getErrors('form.data.subject')"
          required
          :items="subjects"
          label="Предмет"
        ></v-select>

        <v-select
          v-model="$v.form.data.grade.$model"
          :error-messages="getErrors('form.data.grade')"
          required
          :items="activeGrades"
          multiple
          label="Класс"
        ></v-select>

        <v-row justify="start" align="center">
            <v-col cols="12">
                <v-subheader class="py-1 px-0">Продолжительность</v-subheader>
            </v-col>
            <v-col sm="2">
                <v-select
                  v-model="$v.form.data.duration.value.$model"
                  :error-messages="getErrors('form.data.duration.value')"
                  required
                  :items="duration.values"
                  dense
                ></v-select>
            </v-col>
            <v-col sm="2">
                <v-select
                  v-model="$v.form.data.duration.unit.$model"
                  :error-messages="getErrors('form.data.duration.unit')"
                  required
                  :items="duration.units"
                  dense
                ></v-select>
            </v-col>
        </v-row>

        <v-divider class="my-4" />

        <action-buttons 
            :waiting-save-and-back="waiting.save.back"
            :waiting-save-and-update="waiting.save.update"
            @back="back"
            @save="save"
        />
    </v-form>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import ActionButtons from '@/components/crud/ActionButtons.vue'

export default {
    name: 'LicenseTypeForm',
    components: { ActionButtons },
    props: {
        model: {
            type: Object
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data() {
        return {
            storeModule: 'license_type',
            form: {
                active: true,
                code: '',
                name: '',
                product: '',
                data: {
                    subject: null,
                    grade: null,
                    duration: {
                        value: 1,
                        unit: 'year'
                    }
                }
            },
            skip_subject_watch: false
        };
    },
    watch: {
        ['form.data.subject']: {
            handler () {
                if(!this.skip_subject_watch && _.get(this, 'form.data.grade', null) !== '*')
                    _.set(this, 'form.data.grade', null);

                this.skip_subject_watch = false;
            },
            deep: true
        },
        ['form.data.grade']: {
            handler (val) {
                if(_.includes(val, '*') && _.size(val) !== 1)
                    _.set(this, 'form.data.grade', ['*']);
            },
            deep: true
        }
    },
    computed: {
        ...mapState('license_type', ['subjects']),
        ...mapState('product', {
            products: 'items'
        }),
        ...mapGetters('license_type', ['grades']),
        activeSubject() {
            return _.find(this.subjects, {value: _.get(this, 'form.data.subject')});
        },
        activeGrades() {
            const hasAll = _.includes(_.isArray(this.form.data.grade) ? this.form.data.grade : [this.form.data.grade], '*');
            return _.map(this.grades, (grade) => {
                if(hasAll)
                    grade['disabled'] = grade.value !== '*';
                else if(grade.value === '*')
                    grade['disabled'] = false;
                else
                    grade['disabled'] = !_.includes(_.get(this, 'activeSubject.grades', []), grade.value);
                return grade;
            })
        },
        duration() {
            return {
                values: _.map(_.keys(Array(10)), (i) => {
                    i = _.parseInt(i);
                    return {
                        value: i + 1,
                        text: i + 1
                    };
                }),
                units: [{
                    value: 'day',
                    text: 'дн.'
                },{
                    value: 'month',
                    text: 'мес.'
                },{
                    value: 'year',
                    text: 'г.'
                }]
            }
        }
        
    },
    validations() {
        const STRING_FIELD_MIN_LENGTH = 2;
        return {
            form: {
                code: {
                    required,
                    minLength: minLength(STRING_FIELD_MIN_LENGTH),
                    pattern: function(value) {
                        return /^([a-zA-Z]+)([a-zA-Z0-9_-]*)$/.test(value);
                    }
                },
                name: {
                    required,
                    minLength: minLength(STRING_FIELD_MIN_LENGTH)
                },
                product: {
                    required
                },
                data: {
                    subject: {
                        required
                    },
                    grade: {
                        required
                    },
                    duration: {
                        value: {
                            required,
                            pattern: function(value) {
                                return value >= 1 && value <= 10;
                            }
                        },
                        unit: {
                            required
                        }
                    }
                }
            }
        }
    },
    created() {
        if(this.action === 'update')
        {
            this.skip_subject_watch = true;
            _.assign(this.form, this.model);
        }
    }
}
</script>
<style scoped lang="scss">

</style>